import { Action } from '@ngrx/store';

import { IOperatorStore } from '../interfaces';
import { StoreStatus } from '../enums/store-status.enum';
import {PROJECT_SELECTED, PROJECT_UNSELECTED} from './project.store';

export interface OperatorAction extends Action {
  type: string;
  payload?: any;
}

export const OPERATOR_LIST_LOAD = '[Operator] List Load';
export const OPERATOR_LIST_LOADED_SUCCESS = '[Operator] List Loaded Success';
export const OPERATOR_LIST_LOADED_ERROR = '[Operator] List Loaded Error';

export const OPERATOR_LOAD = '[Operator] Load';
export const OPERATOR_LOADED_SUCCESS = '[Operator] Loaded Success';
export const OPERATOR_LOADED_ERROR = '[Operator] Loaded Error';

export const OPERATOR_CREATE = '[Operator] Create';
export const OPERATOR_CREATED_SUCCESS = '[Operator] Created Success';
export const OPERATOR_CREATED_ERROR = '[Operator] Created Error';

export const OPERATOR_UPDATE = '[Operator] Update';
export const OPERATOR_UPDATED_SUCCESS = '[Operator] Updated Success';
export const OPERATOR_UPDATED_ERROR = '[Operator] Updated Error';

export const OPERATOR_DELETE = '[Operator] Delete';
export const OPERATOR_DELETED_SUCCESS = '[Operator] Delete Success';
export const OPERATOR_DELETED_ERROR = '[Operator] Delete Error';

export const OPERATOR_SELECTED = '[Operator] Select';
export const OPERATOR_UNSELECTED = '[Operator] Unselect';

const initialState: IOperatorStore = {
  list: [],
  listStatus: null,
  operator: null,
  operatorSelectedId: null,
  operatorStatus: null,
  createStatus: null,
  updateStatus: null,
};

export function operatorReducer(state: IOperatorStore = initialState, action: OperatorAction) {
  switch (action.type) {

    // Список операторов
    case OPERATOR_LIST_LOAD:
      return {
        ...state,
        list: [],
        listStatus: StoreStatus.loading,
        operator: null,
        operatorStatus: null,
      };
    case OPERATOR_LIST_LOADED_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listStatus: StoreStatus.success
      };
    case OPERATOR_LIST_LOADED_ERROR:
      return {
        ...state,
        listStatus: StoreStatus.error
      };

    // 1 оператор
    case OPERATOR_LOAD:
      return {
        ...state,
        operator: null,
        operatorStatus: StoreStatus.loading
      };
    case OPERATOR_LOADED_SUCCESS:
      return {
        ...state,
        operator: action.payload,
        operatorStatus: StoreStatus.success
      };
    case OPERATOR_LOADED_ERROR:
      return {
        ...state,
        operatorStatus: StoreStatus.error
      };

    // Создать оператора
    case OPERATOR_CREATE:
      return {
        ...state,
        createStatus: StoreStatus.loading
      };
    case OPERATOR_CREATED_SUCCESS:
      return {
        ...state,
        createStatus: StoreStatus.success
      };
    case OPERATOR_CREATED_ERROR:
      return {
        ...state,
        createStatus: StoreStatus.error
      };

    // Обновить оператора
    case OPERATOR_UPDATE:
      return {
        ...state,
        updateStatus: StoreStatus.loading
      };
    case OPERATOR_UPDATED_SUCCESS:
      return {
        ...state,
        operator: action.payload,
        updateStatus: StoreStatus.success
      };
    case OPERATOR_UPDATED_ERROR:
      return {
        ...state,
        updateStatus: StoreStatus.error
      };

    // Удалить оператора
    case OPERATOR_DELETE:
      return {
        ...state,
        updateStatus: StoreStatus.loading
      };
    case OPERATOR_DELETED_SUCCESS:
      return {
        ...state,
        operatorSelectedId: null,
        list: [...state.list].filter(element => element.id !== action.payload),
        updateStatus: StoreStatus.success
      };
    case OPERATOR_DELETED_ERROR:
      return {
        ...state,
        updateStatus: StoreStatus.error
      };

    // Выбрать оператора из списка
    case OPERATOR_SELECTED:
      return {
        ...state,
        operatorSelectedId: action.payload
      };
    case OPERATOR_UNSELECTED:
      return {
        ...state,
        operatorSelectedId: null
      };

    default:
      return state;
  }
}

import { Action } from '@ngrx/store';

import { StoreStatus } from '../enums/store-status.enum';
import { IReportStore } from '../interfaces/report.interface';

export interface ReportAction extends Action {
  type: string;
  payload?: any;
}

export const REPORT_LIST_LOAD = '[Report] List Load';
export const REPORT_LIST_LOADED_SUCCESS = '[Report] List Loaded Success';
export const REPORT_LIST_LOADED_ERROR = '[Report] List Loaded Error';

export const REPORT_CREATE = '[Report] Create';
export const REPORT_CREATED_SUCCESS = '[Report] Created Success';
export const REPORT_CREATED_ERROR = '[Report] Created Error';

export const REPORT_DELETE = '[Report] Delete';
export const REPORT_DELETED_SUCCESS = '[Report] Delete Success';
export const REPORT_DELETED_ERROR = '[Report] Delete Error';

export const REPORT_USER_LIST_LOAD = '[Report] User List Load';
export const REPORT_USER_LIST_LOADED_SUCCESS = '[Report] User List Loaded Success';
export const REPORT_USER_LIST_LOADED_ERROR = '[Report] User List Loaded Error';

export const REPORT_USER_CREATE = '[Report] User Create';
export const REPORT_USER_CREATED_SUCCESS = '[Report] User Created Success';
export const REPORT_USER_CREATED_ERROR = '[Report] User Created Error';

export const REPORT_USER_DELETE = '[Report] User Delete';
export const REPORT_USER_DELETED_SUCCESS = '[Report] User Delete Success';
export const REPORT_USER_DELETED_ERROR = '[Report] User Delete Error';

const initialState: IReportStore = {
  list: null,
  listStatus: null,
  reportStatus: null,
  createStatus: null,
  deleteStatus: null,
};

export function reportReducer(state: any = initialState, action: any) {
  switch (action.type) {

    // Список отчетов проекта
    case REPORT_LIST_LOAD:
      return {
        ...state,
        list: [],
        listStatus: StoreStatus.loading,
      };
    case REPORT_LIST_LOADED_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listStatus: StoreStatus.success
      };
    case REPORT_LIST_LOADED_ERROR:
      return {
        ...state,
        listStatus: StoreStatus.error
      };

    // Создать отчет
    case REPORT_CREATE:
      return {
        ...state,
        createStatus: StoreStatus.loading,
      };
    case REPORT_CREATED_SUCCESS:
      return {
        ...state,
        createStatus: StoreStatus.success
      };
    case REPORT_CREATED_ERROR:
      return {
        ...state,
        createStatus: StoreStatus.error
      };

    // Удалить отчет
    case REPORT_DELETE:
      return {
        ...state,
        deleteStatus: StoreStatus.loading,
      };
    case REPORT_DELETED_SUCCESS:
      return {
        ...state,
        deleteStatus: StoreStatus.success
      };
    case REPORT_DELETED_ERROR:
      return {
        ...state,
        deleteStatus: StoreStatus.error
      };

    // Список отчетов пользователя
    case REPORT_USER_LIST_LOAD:
      return {
        ...state,
        list: [],
        listStatus: StoreStatus.loading,
      };
    case REPORT_USER_LIST_LOADED_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listStatus: StoreStatus.success
      };
    case REPORT_USER_LIST_LOADED_ERROR:
      return {
        ...state,
        listStatus: StoreStatus.error
      };

    // Создать отчет пользователя
    case REPORT_USER_CREATE:
      return {
        ...state,
        createStatus: StoreStatus.loading,
      };
    case REPORT_USER_CREATED_SUCCESS:
      return {
        ...state,
        createStatus: StoreStatus.success
      };
    case REPORT_USER_CREATED_ERROR:
      return {
        ...state,
        createStatus: StoreStatus.error
      };

    // Удалить отчет пользователя
    case REPORT_USER_DELETE:
      return {
        ...state,
        deleteStatus: StoreStatus.loading,
      };
    case REPORT_USER_DELETED_SUCCESS:
      return {
        ...state,
        deleteStatus: StoreStatus.success
      };
    case REPORT_USER_DELETED_ERROR:
      return {
        ...state,
        deleteStatus: StoreStatus.error
      };

    default:
      return state;
  }
}

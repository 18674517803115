import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './core/layout/full/layout.component';
import { BlankComponent } from './core/layout/blank/blank.component';
import { NotFoundComponent } from './core/not-found/not-found.component';


const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'pages/dashboard', pathMatch: 'full' },
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '404',
        component: NotFoundComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProjectType } from '../enums/project-type.enum';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(
    private httpClient: HttpClient
  ) {}

  private getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
      if (key === 'config') {
        return formData.append(key, JSON.stringify(object[key]));
      }
      formData.append(key, object[key]);
    });
    return formData;
  }

  getProjectList() {
    return this.httpClient.get('/api/project/list');
  }

  getProject(projectId: number) {
    return this.httpClient.get('/api/project/info?id=' + projectId);
  }

  createProject(project) {
    return this.httpClient.post('/api/project/new', this.getFormData(project));
  }

  updateProject(project) {
    return this.httpClient.post('/api/project/edit', this.getFormData(project));
  }

  deleteProject(id: number) {
    return this.httpClient.post('/api/project/delete', this.getFormData(id));
  }

  getProjectDataset(project) {
    if (project.type === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.get('/api/task/audio-annotation/next?id=' + project.id);
    }
    if (project.type === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.get('/api/task/text-annotation/next?id=' + project.id);
    }
    return this.httpClient.get('/api/task/read-text/next?id=' + project.id);
  }

  completeDatasetAudio(dataset) {
    return this.httpClient.post('/api/task/read-text/set-done', this.getFormData(dataset));
  }

  updateDatasetAudio(datasetInfo) {
    return this.httpClient.post('/api/task/read-text/save-file', datasetInfo);
  }

  reportDatasetAudio(datasetInfo, projectType) {
    if (projectType === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.post('/api/task/audio-annotation/set-task-no-correct', this.getFormData(datasetInfo));
    }
    if (projectType === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.post('/api/task/text-annotation/set-task-no-correct', this.getFormData(datasetInfo));
    }
    return this.httpClient.post('/api/task/read-text/set-report', this.getFormData(datasetInfo));
  }

  skipDatasetAudio(datasetInfo) {
    return this.httpClient.post('/api/task/read-text/lower-priority', datasetInfo);
  }

  getProjectDatasetInfo(datasetId: number) {
    return this.httpClient.get('/api/task/read-text/info?id=' + datasetId);
  }

  getProjectDatasetTask(datasetId: number, projectType?) {
    if (projectType && projectType === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.get('/api/task/audio-annotation/file?id=' + datasetId, { responseType: 'blob', observe: 'response' });
    }
    if (projectType && projectType === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.get('/api/task/text-annotation/get-text?id=' + datasetId, { observe: 'response' });
    }
    return this.httpClient.get('/api/task/read-text/file?id=' + datasetId, { responseType: 'blob', observe: 'response' });
  }

  setDatasetResult(result, projectType) {
    if (projectType && projectType === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.post('/api/task/audio-annotation/set-result', result);
    }
    if (projectType && projectType === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.post('/api/task/text-annotation/set-result', result);
    }
  }

  getProjectControl(project) {
    if (project.type === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.get('/api/task/audio-annotation/next-doubtful?id=' + project.id);
    }
    if (project.type === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.get('/api/task/text-annotation/next-doubtful?id=' + project.id);
    }
  }

  getProjectCorrect(projectType, id) {
    if (projectType === ProjectType.AUDIO_ANNOTATION) {
      return this.httpClient.post('/api/task/audio-annotation/set-correct-answer', {id});
    }
    if (projectType === ProjectType.TEXT_ANNOTATION) {
      return this.httpClient.post('/api/task/text-annotation/set-correct-answer', {id});
    }
  }

  startProject(formData) {
    return this.httpClient.post('/api/project/activate', formData);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public CONFIG = null;

  constructor(
    private httpClient: HttpClient
  ) { }

  load() {
    return this.httpClient.get('./assets/json/config.json')
      .pipe(
        map((data) => {
          this.CONFIG = data;
          return data;
        }));
  }

  getConfig() {
    return this.CONFIG ? of(this.CONFIG) : this.load();
  }

}

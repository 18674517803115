import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

import {
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AuthAction,
} from '../store/auth.store';

import { IAuthStore } from '../interfaces';
import { UserRole } from '../enums/user-role.enum';


@Injectable()
export class AuthEffects {

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
  ) {}

  @Effect()
  authLogin$: Observable<AuthAction> = this.actions$.pipe(
    ofType(AUTH_LOGIN),
    mergeMap((action: any) => {
      const actions = [];
      const loginData = action.payload;
      return from(this.authService.login(loginData)).pipe(
        mergeMap((data: any) => {
          const authStoreData: IAuthStore = {
            token: data.token,
            userInfo: {
              id: data.id,
              name: data.name,
              login: data.login,
              role: data.roles[0]
            }
          };
          actions.push({ type: AUTH_LOGIN_SUCCESS, payload: {...authStoreData } });

          if (authStoreData.userInfo.role === UserRole.SUPERVISOR) {
            this.router.navigate(['/pages/dashboard']);
          }
          if (authStoreData.userInfo.role === UserRole.OPERATOR) {
            this.router.navigate(['/pages/projects/list']);
          }

          return actions;
        }),
        catchError(error => {
          actions.push({ type: AUTH_LOGIN_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

}

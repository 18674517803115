import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { IAuthStore } from '../../../interfaces';
import { AUTH_LOGOUT } from '../../../store/auth.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  public userName: string;

  constructor(
    private store$: Store<any>,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.store$.select('auth')
      .subscribe((data: IAuthStore) => {
        if (data.userInfo) {
          this.userName = data.userInfo.name;
        }
      });
  }

  public onLogout() {
    this.store$.dispatch({ type: AUTH_LOGOUT });
    this.router.navigate(['/login']);
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

import { Action } from '@ngrx/store';

export interface MessageAction extends Action {
  type: string;
  payload?: any;
}

export const MESSAGE_SHOW = '[Message] Show';
export const MESSAGE_SHOWED = '[Message] Showed';


const initialState = {};

export function messageReducer(state: any = initialState, action: any) {
  switch (action.type) {

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private httpClient: HttpClient
  ) { }


  getReportList(id: number) {
    return this.httpClient.get('/api/report/list?id=' + id);
  }

  createReport(formData: FormData) {
    return this.httpClient.post('/api/report/new', formData);
  }

  getUserReportList() {
    return this.httpClient.get('/api/report/user/list');
  }

  createUserReport(formData: FormData) {
    return this.httpClient.post('/api/report/user/new', formData);
  }

  deleteReport(formData: FormData) {
    return this.httpClient.post('/api/report/delete', formData);
  }

  downloadReport(id: number) {
    return this.httpClient.get('/api/report/file?id=' + id, { responseType: 'blob' });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAuthStore } from '../../interfaces';
import { UserRole } from '../../enums/user-role.enum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  private mainPage = '/login';

  constructor(
    private store$: Store<any>,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.store$.select('auth')
      .subscribe((auth: IAuthStore) => {
        if (!auth.userInfo) {
          return;
        }
        const role = auth.userInfo.role;
        if (role === UserRole.SUPERVISOR) {
          this.mainPage = '/pages/dashboard';
        }
        if (role === UserRole.OPERATOR) {
          this.mainPage = '/pages/projects/list';
        }
      });
  }

  public onBack() {
    this.router.navigate([this.mainPage]);
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

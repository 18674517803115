import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';

import { OperatorService } from '../services/operator.service';
import {
  OPERATOR_LIST_LOAD,
  OPERATOR_LIST_LOADED_SUCCESS,
  OPERATOR_LIST_LOADED_ERROR,

  OPERATOR_LOAD,
  OPERATOR_LOADED_SUCCESS,
  OPERATOR_LOADED_ERROR,

  OPERATOR_CREATE,
  OPERATOR_CREATED_SUCCESS,
  OPERATOR_CREATED_ERROR,

  OPERATOR_UPDATE,
  OPERATOR_UPDATED_SUCCESS,
  OPERATOR_UPDATED_ERROR,

  OPERATOR_DELETE,
  OPERATOR_DELETED_SUCCESS,
  OPERATOR_DELETED_ERROR,

  OperatorAction,
} from '../store/operator.store';
import { MESSAGE_SHOW } from '../store/message.store';


@Injectable()
export class OperatorEffects {

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private operatorService: OperatorService,
    private router: Router,
  ) {}

  @Effect()
  loadOperatorList$: Observable<OperatorAction> = this.actions$.pipe(
    ofType(OPERATOR_LIST_LOAD),
    mergeMap((action: any) => {
      const actions = [];

      return from(this.operatorService.getOperatorList()).pipe(
        mergeMap((data: any) => {
          actions.push({ type: OPERATOR_LIST_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: OPERATOR_LIST_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  loadOperator$: Observable<OperatorAction> = this.actions$.pipe(
    ofType(OPERATOR_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const operatorId = action.payload;

      return from(this.operatorService.getOperator(operatorId)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: OPERATOR_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: OPERATOR_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  createOperator$: Observable<OperatorAction> = this.actions$.pipe(
    ofType(OPERATOR_CREATE),
    mergeMap((action: any) => {
      const actions = [];
      const operator = action.payload;

      return from(this.operatorService.createOperator(operator)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: OPERATOR_CREATED_SUCCESS });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно создано' }});
          this.router.navigate(['/pages/dashboard'], {
            state: {
              tab: 2
            }
          });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: OPERATOR_CREATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  updateOperator$: Observable<OperatorAction> = this.actions$.pipe(
    ofType(OPERATOR_UPDATE),
    mergeMap((action: any) => {
      const actions = [];
      const operator = action.payload;

      return from(this.operatorService.updateOperator(operator)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: OPERATOR_UPDATED_SUCCESS, payload: data.operator });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно обновлено' }});
          this.router.navigate(['/pages/dashboard'], {
            state: {
              tab: 2
            }
          });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: OPERATOR_UPDATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  deleteOperator$: Observable<OperatorAction> = this.actions$.pipe(
    ofType(OPERATOR_DELETE),
    mergeMap((action: any) => {
      const actions = [];
      const operator = action.payload;

      return from(this.operatorService.deleteOperator(operator)).pipe(
        withLatestFrom(of(operator)),
        mergeMap(([data, operatorData]: [any, any]) => {
          actions.push({ type: OPERATOR_DELETED_SUCCESS, payload: operatorData.id });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно удалено' }});
          return actions;
        }),
        catchError(error => {
          actions.push({ type: OPERATOR_DELETED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }

  getOperatorList() {
    return this.httpClient.get('/api/user/list');
  }

  getOperator(operatorId: number) {
    return this.httpClient.get('/api/user/info-by-id?id=' + operatorId);
  }

  createOperator(operator) {
    return this.httpClient.post('/api/user/new', this.getFormData(operator));
  }

  updateOperator(operator) {
    return this.httpClient.post('/api/user/edit', this.getFormData(operator));
  }

  deleteOperator(id) {
    return this.httpClient.post('/api/user/delete', this.getFormData(id));
  }
}

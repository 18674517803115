import { Action } from '@ngrx/store';

import { IAuthStore } from '../interfaces';

export interface AuthAction extends Action {
  type: string;
  payload?: any;
}

export const AUTH_LOGIN = '[Auth] Login';
export const AUTH_LOGIN_SUCCESS = '[Auth] Login Success';
export const AUTH_LOGIN_ERROR = '[Auth] Login Error';

export const AUTH_LOGOUT = '[Auth] Logout';


const initialState: IAuthStore = {
  userInfo: null,
  token: null,
};

export function authReducer(state: IAuthStore = initialState, action: AuthAction) {
  switch (action.type) {

    case AUTH_LOGIN:
      return {
        ...state,
        userInfo: null,
        token: null
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        token: action.payload.token
      };

    case AUTH_LOGIN_ERROR:
      return {
        ...state,
      };

    case AUTH_LOGOUT:
      return {
        ...state,
        userInfo: null,
        token: null
      };

    default:
      return state;
  }
}

import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, MetaReducer } from '@ngrx/store';

import { AUTH_LOGOUT, authReducer } from './auth.store';
import { operatorReducer } from './operator.store';
import { projectReducer } from './project.store';
import { messageReducer } from './message.store';
import { reportReducer } from './report.store';

export const forRoot = {
  auth: authReducer,
  message: messageReducer,
  operator: operatorReducer,
  project: projectReducer,
  report: reportReducer,
};


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth'], rehydrate: true })(reducer);
}

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === AUTH_LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  clearState
];

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ConfigService } from '../config.service';

import { MESSAGE_SHOW } from '../../store/message.store';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';


@Injectable()
export class UrlHttpInterceptor implements HttpInterceptor {
  private isAuth = true;

  constructor(
    private store$: Store<any>,
    private configService: ConfigService,
    private router: Router,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;

    if (reqUrl.indexOf('.json') !== -1 ) {
      return next.handle(req);
    }

    return this.configService.getConfig()
      .pipe(
        withLatestFrom(this.store$),
        catchError((error) => {
          this.showError(error);
          return throwError(error);
        }),
        switchMap(([data, state]: [any, any]) => {
          this.isAuth = state.auth.userInfo || reqUrl.includes('/login');
          if (!this.isAuth) {
            return EMPTY;
          }

          req = req.clone({
            url: environment.backendUrl + reqUrl
          });

          const token = state.auth.token;
          if (token) {
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
          }

          return next.handle(req)
            .pipe(
              catchError(error => {
                this.showError(error);
                return throwError(error);
              })
            );
        }));

  }

  private showError(error) {
    let message;

    if (error.status === 401) {
      message = 'Ошибка при авторизации';
      this.router.navigate(['/login']);
    } else if (error.error && error.error.description) {
      message =  error.error.description;
    } else if (error.error && error.error.message) {
      message =  error.error.message;
    } else {
      let key;
      switch (error.status) {
        case 400:
          key = 'В запросе переданы некорректные данные';
          break;
        case 403:
          key = 'Ошибка доступа';
          break;
        case 404:
          key = 'Не найден запрашиваемый ресурс';
          break;
        default:
          key = 'Ошибка при запросе к серверу';
      }
      message = key;
    }
    this.store$.dispatch({ type: MESSAGE_SHOW, payload: { title: message, type: 'error'} });
  }
}

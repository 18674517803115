import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { ReportService } from '../services/report.service';

import {
  REPORT_LIST_LOAD,
  REPORT_LIST_LOADED_ERROR,
  REPORT_LIST_LOADED_SUCCESS,

  REPORT_CREATE,
  REPORT_CREATED_SUCCESS,
  REPORT_CREATED_ERROR,

  REPORT_DELETE,
  REPORT_DELETED_SUCCESS,
  REPORT_DELETED_ERROR,

  REPORT_USER_LIST_LOAD,
  REPORT_USER_LIST_LOADED_SUCCESS,
  REPORT_USER_LIST_LOADED_ERROR,

  REPORT_USER_CREATE,
  REPORT_USER_CREATED_SUCCESS,
  REPORT_USER_CREATED_ERROR,

  REPORT_USER_DELETE,
  REPORT_USER_DELETED_SUCCESS,
  REPORT_USER_DELETED_ERROR,

  ReportAction,
} from '../store/report.store';


@Injectable()
export class ReportEffects {

  constructor(
    private actions$: Actions,
    private reportService: ReportService
  ) {}

  @Effect()
  loadReportList$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_LIST_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const id = action.payload;

      return from(this.reportService.getReportList(id)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_LIST_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_LIST_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  createReport$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_CREATE),
    mergeMap((action: any) => {
      const actions = [];
      const projectId = action.payload.id;

      const formData = new FormData();
      formData.append('id', projectId);
      formData.append('type', action.payload.type );
      if (action.payload.periodFrom) {
        formData.append('periodFrom', action.payload.periodFrom );
        formData.append('periodTo', action.payload.periodTo );
      }

      return from(this.reportService.createReport(formData)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_CREATED_SUCCESS, payload: data });
          actions.push({ type: REPORT_LIST_LOAD, payload: projectId });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_CREATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  deleteReport$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_DELETE),
    mergeMap((action: any) => {
      const actions = [];
      const projectId = action.payload.projectId;
      const id = action.payload.id;

      const formData = new FormData();
      formData.append('id', id);

      return from(this.reportService.deleteReport(formData)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_DELETED_SUCCESS, payload: data });
          actions.push({ type: REPORT_LIST_LOAD, payload: projectId });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_DELETED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  loadUsreReportList$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_USER_LIST_LOAD),
    mergeMap((action: any) => {
      const actions = [];

      return from(this.reportService.getUserReportList()).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_USER_LIST_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_USER_LIST_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  createUserReport$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_USER_CREATE),
    mergeMap((action: any) => {
      const actions = [];

      const formData = new FormData();
      formData.append('type', action.payload.type );
      formData.append('projectType', action.payload.projectType );
      if (action.payload.periodFrom) {
        formData.append('periodFrom', action.payload.periodFrom );
        formData.append('periodTo', action.payload.periodTo );
      }

      return from(this.reportService.createUserReport(formData)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_USER_CREATED_SUCCESS, payload: data });
          actions.push({ type: REPORT_USER_LIST_LOAD });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_USER_CREATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  deleteUserReport$: Observable<ReportAction> = this.actions$.pipe(
    ofType(REPORT_USER_DELETE),
    mergeMap((action: any) => {
      const actions = [];
      const id = action.payload.id;

      const formData = new FormData();
      formData.append('id', id);

      return from(this.reportService.deleteReport(formData)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: REPORT_USER_DELETED_SUCCESS, payload: data });
          actions.push({ type: REPORT_USER_LIST_LOAD });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: REPORT_USER_DELETED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {ActivatedRoute, Router} from '@angular/router';
import { from, Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { ProjectService } from '../services/project.service';
import {
  PROJECT_LIST_LOAD,
  PROJECT_LIST_LOADED_SUCCESS,
  PROJECT_LIST_LOADED_ERROR,

  PROJECT_LOAD,
  PROJECT_LOADED_SUCCESS,
  PROJECT_LOADED_ERROR,

  PROJECT_CREATE,
  PROJECT_CREATED_SUCCESS,
  PROJECT_CREATED_ERROR,

  PROJECT_UPDATE,
  PROJECT_UPDATED_SUCCESS,
  PROJECT_UPDATED_ERROR,

  PROJECT_DELETE,
  PROJECT_DELETED_SUCCESS,
  PROJECT_DELETED_ERROR,

  PROJECT_DATASET_LOAD,
  PROJECT_DATASET_LOADED_SUCCESS,
  PROJECT_DATASET_LOADED_ERROR,

  PROJECT_DATASET_COMPLETE,
  PROJECT_DATASET_COMPLETED_SUCCESS,
  PROJECT_DATASET_COMPLETED_ERROR,

  PROJECT_DATASET_UPDATE,
  PROJECT_DATASET_UPDATED_SUCCESS,
  PROJECT_DATASET_UPDATED_ERROR,

  PROJECT_DATASET_INFO_LOAD,
  PROJECT_DATASET_INFO_LOADED_SUCCESS,
  PROJECT_DATASET_INFO_LOADED_ERROR,

  PROJECT_DATASET_REPORT,
  PROJECT_DATASET_REPORTED_SUCCESS,
  PROJECT_DATASET_REPORTED_ERROR,

  PROJECT_DATASET_SKIP,
  PROJECT_DATASET_SKIPPED_SUCCESS,
  PROJECT_DATASET_SKIPPED_ERROR,

  PROJECT_CONTROL_LOAD,
  PROJECT_CONTROL_LOADED_SUCCESS,
  PROJECT_CONTROL_LOADED_ERROR,

  PROJECT_CONTROL_CORRECT,
  PROJECT_CONTROL_CORRECTED_SUCCESS,
  PROJECT_CONTROL_CORRECTED_ERROR,

  PROJECT_CONTROL_RESULT,
  PROJECT_CONTROL_RESULTED_SUCCESS,
  PROJECT_CONTROL_RESULTED_ERROR,

  PROJECT_DATASET_RESULT,

  ProjectAction,
  PROJECT_START,
  PROJECT_STARTED_SUCCESS,
  PROJECT_STARTED_ERROR,
} from '../store/project.store';
import { MESSAGE_SHOW } from '../store/message.store';
import {withLatestFrom} from 'rxjs/internal/operators/withLatestFrom';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class ProjectEffects {

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  @Effect()
  loadProjectList$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_LIST_LOAD),
    mergeMap((action: any) => {
      const actions = [];

      return from(this.projectService.getProjectList()).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_LIST_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_LIST_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  loadProject$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const projectId = action.payload;

      return from(this.projectService.getProject(projectId)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  createProject$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_CREATE),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload;

      return from(this.projectService.createProject(project)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_CREATED_SUCCESS });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно создано' }});
          this.router.navigate(['/pages/dashboard']);
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_CREATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  updateProject$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_UPDATE),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload;

      return from(this.projectService.updateProject(project)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_UPDATED_SUCCESS, payload: data.project });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно обновлено' }});
          this.router.navigate(['/pages/dashboard']);
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_UPDATED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  deleteProject$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DELETE),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload;

      return from(this.projectService.deleteProject(project)).pipe(
        withLatestFrom(of(project)),
        mergeMap(([data, projectData]: [any, any]) => {
          actions.push({ type: PROJECT_DELETED_SUCCESS, payload: projectData.id });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Успешно удалено' }});
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DELETED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetLoad$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload;

      return from(this.projectService.getProjectDataset(project)).pipe(
        mergeMap((data: any) => {
          if (data.message) {
            this.router.navigate(['/pages/projects/list']);
            actions.push({ type: MESSAGE_SHOW, payload: { type: 'error', title: data.message }});
          }
          actions.push({ type: PROJECT_DATASET_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          this.router.navigate(['/pages/projects/list']);
          actions.push({ type: PROJECT_DATASET_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetComplete$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_COMPLETE),
    mergeMap((action: any) => {
      const actions = [];
      const dataset = action.payload;

      return from(this.projectService.completeDatasetAudio(dataset)).pipe(
        withLatestFrom(this.store$.select('project')),
        mergeMap(([data, project]: [any, any]) => {
          actions.push({ type: PROJECT_DATASET_COMPLETED_SUCCESS });
          actions.push({ type: PROJECT_DATASET_LOAD, payload: project.project });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_COMPLETED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetUpdate$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_UPDATE),
    mergeMap((action: any) => {
      const actions = [];
      const dataset = action.payload;

      return from(this.projectService.updateDatasetAudio(dataset)).pipe(
        withLatestFrom(of(dataset.get('id'))),
        mergeMap(([data, id]: [any, any]) => {
          actions.push({ type: PROJECT_DATASET_UPDATED_SUCCESS });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success',
              title: `Запись по заданию ${id} успешно загружена` }});
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_UPDATED_ERROR, payload: error });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'error',
              title: `Ошибка при загрузки записи, задание будет добавлено в очередь` }});
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetReport$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_REPORT),
    mergeMap((action: any) => {
      const actions = [];
      const dataset = action.payload.dataset;
      const project = action.payload.project;
      const isControl = action.payload.isControl

      return from(this.projectService.reportDatasetAudio(dataset, project.type)).pipe(
        withLatestFrom(this.store$.select('project')),
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_DATASET_REPORTED_SUCCESS });
          if (isControl) {
            const id = data[0].id;
            actions.push({ type: PROJECT_CONTROL_CORRECT, payload: { project, id } });
            this.router.navigate(['/pages/control/' + project.id]);
            return actions;
          }
          actions.push({ type: PROJECT_DATASET_LOAD, payload: project });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_REPORTED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetSkip$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_SKIP),
    mergeMap((action: any) => {
      const actions = [];
      const dataset = action.payload;

      return from(this.projectService.skipDatasetAudio(dataset)).pipe(
        withLatestFrom(this.store$.select('project')),
        mergeMap(([data, project]: [any, any]) => {
          actions.push({ type: PROJECT_DATASET_SKIPPED_SUCCESS });
          actions.push({ type: PROJECT_DATASET_LOAD, payload: project.project });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_SKIPPED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetInfoLoad$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_INFO_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const datasetId = action.payload;

      return from(this.projectService.getProjectDatasetInfo(datasetId)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_DATASET_INFO_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_INFO_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  datasetaResult$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_DATASET_RESULT),
    mergeMap((action: any) => {
      const actions = [];
      const result = action.payload.data;
      const project = action.payload.project;

      return from(this.projectService.setDatasetResult(result, project.type)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_DATASET_REPORTED_SUCCESS, payload: data });
          actions.push({ type: PROJECT_DATASET_LOAD, payload: project });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_DATASET_REPORTED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  controlLoad$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_CONTROL_LOAD),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload;

      return from(this.projectService.getProjectControl(project)).pipe(
        mergeMap((data: any) => {
          if (data.message) {
            this.router.navigate(['/pages/projects/details/' + project.id]);
            actions.push({ type: MESSAGE_SHOW, payload: { type: 'error', title: data.message }});
          }
          actions.push({ type: PROJECT_CONTROL_LOADED_SUCCESS, payload: data });
          return actions;
        }),
        catchError(error => {
          this.router.navigate(['/pages/projects/details/' + project.id]);
          actions.push({ type: PROJECT_CONTROL_LOADED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  controlСorrect$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_CONTROL_CORRECT),
    mergeMap((action: any) => {
      const actions = [];
      const project = action.payload.project;
      const id = action.payload.id;
      return from(this.projectService.getProjectCorrect(project.type, id)).pipe(
        mergeMap((data: any) => {
          actions.push({ type: PROJECT_CONTROL_CORRECTED_SUCCESS, payload: data });
          actions.push({ type: PROJECT_CONTROL_LOAD, payload: project });
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_CONTROL_CORRECTED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  controlResult$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_CONTROL_RESULT),
    mergeMap((action: any) => {
      const actions = [];
      const result = action.payload.data;
      const project = action.payload.project;

      return from(this.projectService.setDatasetResult(result, project.type)).pipe(
        mergeMap((data: any) => {
          const id = data.id;
          actions.push({ type: PROJECT_CONTROL_RESULTED_SUCCESS, payload: data });
          actions.push({ type: PROJECT_CONTROL_CORRECT, payload: { project, id } });
          this.router.navigate(['/pages/control/' + project.id]);
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_CONTROL_RESULTED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );

  @Effect()
  start$: Observable<ProjectAction> = this.actions$.pipe(
    ofType(PROJECT_START),
    mergeMap((action: any) => {
      const actions = [];
      const formData = new FormData();
      formData.append('id', action.payload);

      return from(this.projectService.startProject(formData)).pipe(
        mergeMap((data: any) => {
          const id = data.id;
          actions.push({ type: PROJECT_STARTED_SUCCESS, payload: data });
          actions.push({ type: MESSAGE_SHOW, payload: { type: 'success', title: 'Проект запущен' }});
          this.router.navigate(['/pages/dashboard']);
          return actions;
        }),
        catchError(error => {
          actions.push({ type: PROJECT_STARTED_ERROR, payload: error });
          return actions;
        })
      );
    })
  );
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { forRoot as forRootReducers,  metaReducers } from '../store';
import { forRoot as forRootEffects } from '../effects';

import { AppRoutingModule } from '../app-routing.module';

import { LayoutComponent } from './layout/full/layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlankComponent } from './layout/blank/blank.component';


@NgModule({
    declarations: [
        LayoutComponent,
        NotFoundComponent,
        BlankComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        // Модули для работы со стором
        StoreModule.forRoot(forRootReducers, { metaReducers }),
        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot(forRootEffects),
    ],
    exports: [
        LayoutComponent,
        NotFoundComponent
    ]
})
export class CoreModule { }

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import {
  MessageAction,
  MESSAGE_SHOW,
  MESSAGE_SHOWED,
} from '../store/message.store';

@Injectable()
export class MessageEffects {
  /*
    action.payload.type:
      - 'info' => blue
      - 'success' => green
      - 'warning' => orange
      - 'error' => red
 */

  @Effect()
  show$: Observable<MessageAction> = this.actions$.pipe(
    ofType(MESSAGE_SHOW),
    switchMap((action: MessageAction) => {
      this.toastr[action.payload.type || 'info'](action.payload.title || '');
      return of({ type: MESSAGE_SHOWED });
    })
  );

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
  ) {}

}

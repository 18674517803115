import { Action } from '@ngrx/store';
import { IProjectStore } from '../interfaces';
import { StoreStatus } from '../enums/store-status.enum';

export interface ProjectAction extends Action {
  type: string;
  payload?: any;
}

export const PROJECT_LIST_LOAD = '[Project] List Load';
export const PROJECT_LIST_LOADED_SUCCESS = '[Project] List Loaded Success';
export const PROJECT_LIST_LOADED_ERROR = '[Project] List Loaded Error';

export const PROJECT_LOAD = '[Project] Load';
export const PROJECT_LOADED_SUCCESS = '[Project] Loaded Success';
export const PROJECT_LOADED_ERROR = '[Project] Loaded Error';

export const PROJECT_CREATE = '[Project] Create';
export const PROJECT_CREATED_SUCCESS = '[Project] Created Success';
export const PROJECT_CREATED_ERROR = '[Project] Created Error';

export const PROJECT_UPDATE = '[Project] Update';
export const PROJECT_UPDATED_SUCCESS = '[Project] Updated Success';
export const PROJECT_UPDATED_ERROR = '[Project] Updated Error';

export const PROJECT_DELETE = '[Project] Delete';
export const PROJECT_DELETED_SUCCESS = '[Project] Delete Success';
export const PROJECT_DELETED_ERROR = '[Project] Delete Error';

export const PROJECT_SELECTED = '[Project] Select';
export const PROJECT_UNSELECTED = '[Project] Unselect';

export const PROJECT_DATASET_LOAD = '[Project] Dataset Load';
export const PROJECT_DATASET_LOADED_SUCCESS = '[Project] Dataset Loaded Success';
export const PROJECT_DATASET_LOADED_ERROR = '[Project] Dataset Loaded Error';

export const PROJECT_DATASET_COMPLETE = '[Project] Dataset Complete';
export const PROJECT_DATASET_COMPLETED_SUCCESS = '[Project] Dataset Completed Success';
export const PROJECT_DATASET_COMPLETED_ERROR = '[Project] Dataset Completed Error';

export const PROJECT_DATASET_UPDATE = '[Project] Dataset Update';
export const PROJECT_DATASET_UPDATED_SUCCESS = '[Project] Dataset Updated Success';
export const PROJECT_DATASET_UPDATED_ERROR = '[Project] Dataset Updated Error';

export const PROJECT_DATASET_INFO_LOAD = '[Project] Dataset Info Load';
export const PROJECT_DATASET_INFO_LOADED_SUCCESS = '[Project] Dataset Info Loaded Success';
export const PROJECT_DATASET_INFO_LOADED_ERROR = '[Project] Dataset Info Loaded Error';

export const PROJECT_DATASET_REPORT = '[Project] Dataset Report';
export const PROJECT_DATASET_REPORTED_SUCCESS = '[Project] Dataset Report Success';
export const PROJECT_DATASET_REPORTED_ERROR = '[Project] Dataset Report Error';

export const PROJECT_DATASET_SKIP = '[Project] Dataset Skip';
export const PROJECT_DATASET_SKIPPED_SUCCESS = '[Project] Dataset Skipped Success';
export const PROJECT_DATASET_SKIPPED_ERROR = '[Project] Dataset Skipped Error';

export const PROJECT_DATASET_RESULT = '[Project] Dataset Result';
export const PROJECT_DATASET_RESULTPED_SUCCESS = '[Project] Dataset Result Success';
export const PROJECT_DATASET_RESULTPED_ERROR = '[Project] Dataset Result Error';

export const PROJECT_CONTROL_LOAD = '[Project] Control Load';
export const PROJECT_CONTROL_LOADED_SUCCESS = '[Project] Control Loaded Success';
export const PROJECT_CONTROL_LOADED_ERROR = '[Project] Control Loaded Error';

export const PROJECT_CONTROL_CORRECT = '[Project] Control Correct';
export const PROJECT_CONTROL_CORRECTED_SUCCESS = '[Project] Control Correct Success';
export const PROJECT_CONTROL_CORRECTED_ERROR = '[Project] Control Correct Error';

export const PROJECT_CONTROL_RESULT = '[Project] Control Result';
export const PROJECT_CONTROL_RESULTED_SUCCESS = '[Project] Control Result Success';
export const PROJECT_CONTROL_RESULTED_ERROR = '[Project] Control Result Error';

export const PROJECT_START = '[Project] Start Result';
export const PROJECT_STARTED_SUCCESS = '[Project] Start Success';
export const PROJECT_STARTED_ERROR = '[Project] Start Error';

export const PROJECT_CLEAR = '[Project] Clear';

const initialState: IProjectStore = {
  list: [],
  listStatus: null,
  project: null,
  projectSelectedId: null,
  projectStatus: null,
  createStatus: null,
  updateStatus: null,
  dataset: null,
  datasetStatus: null,
  datasetUpdateStatus: null,
  datasetReportStatus: null,
  datasetSkipStatus: null,
  control: null,
  controlStatus: null,
  correct: null,
  correctStatus: null,
};

export function projectReducer(state: IProjectStore = initialState, action: ProjectAction) {
  switch (action.type) {

    // Список проектов
    case PROJECT_LIST_LOAD:
      return {
        ...state,
        list: [],
        listStatus: StoreStatus.loading,
        project: null,
        projectStatus: null,
      };
    case PROJECT_LIST_LOADED_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listStatus: StoreStatus.success
      };
    case PROJECT_LIST_LOADED_ERROR:
      return {
        ...state,
        listStatus: StoreStatus.error
      };

    // 1 проект
    case PROJECT_LOAD:
      return {
        ...state,
        project: null,
        projectStatus: StoreStatus.loading
      };
    case PROJECT_LOADED_SUCCESS:
      return {
        ...state,
        project: action.payload,
        projectStatus: StoreStatus.success
      };
    case PROJECT_LOADED_ERROR:
      return {
        ...state,
        projectStatus: StoreStatus.error
      };

    // Создать проект
    case PROJECT_CREATE:
      return {
        ...state,
        createStatus: StoreStatus.loading
      };
    case PROJECT_CREATED_SUCCESS:
      return {
        ...state,
        createStatus: StoreStatus.success
      };
    case PROJECT_CREATED_ERROR:
      return {
        ...state,
        createStatus: StoreStatus.error
      };

    // Изменить проект
    case PROJECT_UPDATE:
      return {
        ...state,
        updateStatus: StoreStatus.loading
      };
    case PROJECT_UPDATED_SUCCESS:
      return {
        ...state,
        project: action.payload,
        updateStatus: StoreStatus.success
      };
    case PROJECT_UPDATED_ERROR:
      return {
        ...state,
        updateStatus: StoreStatus.error
      };

    // Удалить проект
    case PROJECT_DELETE:
      return {
        ...state,
        updateStatus: StoreStatus.loading
      };
    case PROJECT_DELETED_SUCCESS:
      return {
        ...state,
        projectSelectedId: null,
        list: [...state.list].filter(element => element.id !== action.payload),
        updateStatus: StoreStatus.success
      };
    case PROJECT_DELETED_ERROR:
      return {
        ...state,
        updateStatus: StoreStatus.error
      };

    // Выбрать проект из списка
    case PROJECT_SELECTED:
      return {
        ...state,
        projectSelectedId: action.payload
      };
    case PROJECT_UNSELECTED:
      return {
        ...state,
        projectSelectedId: null
      };

    // Пометить задачу, как выполненную
    case PROJECT_DATASET_COMPLETE:
      return state;
    case PROJECT_DATASET_COMPLETED_SUCCESS:
      return {
        ...state,
        dataset: null,
        datasetStatus: null
      };
    case PROJECT_DATASET_COMPLETED_ERROR:
      return state;

    // Получить фразу для озвучивания
    case PROJECT_DATASET_LOAD:
      return {
        ...state,
        datasetStatus: StoreStatus.loading,
      };
    case PROJECT_DATASET_LOADED_SUCCESS:
      return {
        ...state,
        dataset: action.payload,
        datasetStatus: StoreStatus.success
      };
    case PROJECT_DATASET_LOADED_ERROR:
      return {
        ...state,
        datasetStatus: StoreStatus.error
      };

    // Сохранить аудио озвучивания для фразы
    case PROJECT_DATASET_UPDATE:
      return state;
    case PROJECT_DATASET_UPDATED_SUCCESS:
      return state;
    case PROJECT_DATASET_UPDATED_ERROR:
      return state;

    // Получить информацию о фразе (текст)
    case PROJECT_DATASET_INFO_LOAD:
      return {
        ...state,
        dataset: null,
        datasetStatus: StoreStatus.loading
      };
    case PROJECT_DATASET_INFO_LOADED_SUCCESS:
      return {
        ...state,
        dataset: action.payload,
        datasetStatus: StoreStatus.success
      };
    case PROJECT_DATASET_INFO_LOADED_ERROR:
      return {
        ...state,
        datasetStatus: StoreStatus.error
      };

    // Пометить задачу, как сомнительную
    case PROJECT_DATASET_REPORT:
      return {
        ...state,
        dataset: null,
        datasetReportStatus: StoreStatus.loading
      };
    case PROJECT_DATASET_REPORTED_SUCCESS:
      return {
        ...state,
        datasetReportStatus: StoreStatus.success
      };
    case PROJECT_DATASET_REPORTED_ERROR:
      return {
        ...state,
        datasetReportStatus: StoreStatus.error
      };

    // Понизить приоритет задачи
    case PROJECT_DATASET_SKIP:
      return {
        ...state,
        dataset: null,
        datasetSkipStatus: StoreStatus.loading
      };
    case PROJECT_DATASET_SKIPPED_SUCCESS:
      return {
        ...state,
        datasetSkipStatus: StoreStatus.success
      };
    case PROJECT_DATASET_SKIPPED_ERROR:
      return {
        ...state,
        datasetSkipStatus: StoreStatus.error
      };

    // Отправиль ответа
    case PROJECT_DATASET_RESULT:
      return {
        ...state,
        dataset: null,
        datasetUpdateStatus: StoreStatus.loading
      };
    case PROJECT_DATASET_RESULTPED_SUCCESS:
      return {
        ...state,
        datasetUpdateStatus: StoreStatus.success
      };
    case PROJECT_DATASET_RESULTPED_ERROR:
      return {
        ...state,
        datasetUpdateStatus: StoreStatus.error
      };

    // Получить задание для проверки
    case PROJECT_CONTROL_LOAD:
      return {
        ...state,
        controlStatus: StoreStatus.loading,
      };
    case PROJECT_CONTROL_LOADED_SUCCESS:
      return {
        ...state,
        control: action.payload,
        controlStatus: StoreStatus.success
      };
    case PROJECT_CONTROL_LOADED_ERROR:
      return {
        ...state,
        controlStatus: StoreStatus.error
      };

    // Выбрать того, кто сделал заданиеправильно
    case PROJECT_CONTROL_CORRECT:
      return {
        ...state,
        correctStatus: StoreStatus.loading,
      };
    case PROJECT_CONTROL_CORRECTED_SUCCESS:
      return {
        ...state,
        correct: action.payload,
        correctStatus: StoreStatus.success
      };
    case PROJECT_CONTROL_CORRECTED_ERROR:
      return {
        ...state,
        correctStatus: StoreStatus.error
      };

    case PROJECT_CONTROL_RESULT:
      return {
        ...state,
        dataset: null,
        datasetUpdateStatus: StoreStatus.loading
      };
    case PROJECT_CONTROL_RESULTED_SUCCESS:
      return {
        ...state,
        datasetUpdateStatus: StoreStatus.success
      };
    case PROJECT_CONTROL_RESULTED_ERROR:
      return {
        ...state,
        datasetUpdateStatus: StoreStatus.error
      };

    // Запустить проект
    case PROJECT_START:
      return {
        ...state,
        createStatus: StoreStatus.loading
      };
    case PROJECT_STARTED_SUCCESS:
      return {
        ...state,
        createStatus: StoreStatus.success
      };
    case PROJECT_STARTED_ERROR:
      return {
        ...state,
        createStatus: StoreStatus.error
      };

    case PROJECT_CLEAR:
      return {...initialState};

    default:
      return state;
  }
}


import { AuthEffects } from './auth.effects';
import { MessageEffects } from './message.effects';
import { OperatorEffects } from './operator.effects';
import { ProjectEffects } from './project.effects';
import { ReportEffects } from './report.effects';

export const forRoot = [
  AuthEffects,
  MessageEffects,
  OperatorEffects,
  ProjectEffects,
  ReportEffects,
];

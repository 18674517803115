export enum ProjectType {
  READING = 'TEXT_READING',
  TRANSCRIPTION = 'TRANSCRIBING_PHRASES',
  AUDIO_ANNOTATION = 'AUDIO_ANNOTATION',
  TEXT_ANNOTATION = 'TEXT_ANNOTATION',
}

export enum ProjectTypeTitle {
  READING = 'Начитка',
  TRANSCRIPTION = 'Траснскрибация',
  AUDIO_ANNOTATION = 'Аннотирование по аудио',
  TEXT_ANNOTATION = 'Аннотирование по тексту',
}
